import { gql } from 'apollo-boost'
import * as React from 'react'
import { Mutation, MutationFn } from 'react-apollo'
import { createSelector } from 'reselect'
import styled from 'styled-components'
import { LIST_COUNTRIES } from '../../services/constants/form'
import {
  validateEmail,
  validateTelephone,
  validateUserType,
  validateWholeForm,
} from '../../services/validation'
import Dropdown from '../Dropdown'
import Form from '../Form'
import GetReCaptchaToken from '../GetReCaptchaToken'
import Message from '../Message'
import TextArea from '../TextArea'
import GoogleTCs from './components/GoogleTCs'

export type ContactFormProps = {
  as?: React.ReactType
  className?: string
  id?: string
}

const SEND_FORM_MUTATION = gql`
  mutation form($brand: String!, $form: ContactFormInput!, $token: String!) {
    sendForm(brand: $brand, form: $form, token: $token) {
      message
    }
  }
`

const FormMessage = styled(Message)`
  margin-top: 1.5em;
`

type ContactFormData = {
  firstName: string
  lastName: string
  email: string
  phone: string
  userType: string
  companyWebsite: string
  companyName: string
  content: string
  country: string
  postcode: string
  city: string
  isJoiningMailingList: boolean
}

export type ContactFormState = {
  formData: ContactFormData
  showFullValidation: boolean // used only to control the userType and country values..
}

class ContactForm extends React.Component<ContactFormProps, ContactFormState> {
  static defaultProps = {
    as: 'div',
    id: 'contact-form',
  }

  static get initialState(): ContactFormState {
    return {
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        country: 'United Kingdom',
        city: '',
        postcode: '',
        userType: '',
        companyWebsite: '',
        companyName: '',
        content: '',
        isJoiningMailingList: false,
      },
      showFullValidation: false,
    }
  }

  state: ContactFormState = ContactForm.initialState

  selectFields = createSelector(
    (state: ContactFormState) => state.formData,
    ({ userType }) => {
      const fields = [
        [
          { label: 'First name', name: 'firstName', required: true },
          { label: 'Last name', name: 'lastName', required: true },
        ],
        [
          {
            label: 'Email',
            name: 'email',
            placeholder: 'my@mail.co.uk',
            type: 'email',
            validate: validateEmail,
            required: true,
          },
          {
            label: 'Phone',
            name: 'phone',
            placeholder: '+44 1234 56789',
            type: 'tel',
            validate: validateTelephone,
          },
        ],
        [
          {
            label: 'Country',
            name: 'country',
            options: LIST_COUNTRIES,
            control: Dropdown,
            required: true,
          },
          {
            label: 'City',
            name: 'city',
            placeholder: 'London',
            required: true,
          },
        ],
        [
          {
            label: 'Postcode',
            name: 'postcode',
            placeholder: 'AB1 2CD',
            required: true,
          },
          {
            label: 'What best describes you?',
            name: 'userType',
            required: true,
            options: [
              'Printer/Embroiderer',
              'Distributor',
              'Reseller',
              'User/Wearer',
              'Other',
            ],
            control: Dropdown,
            validate: validateUserType,
            info: {
              value: `AWDis work exclusively with B2B customers.
              If your query is about customised/previously purchased products please speak to your local provider - printer, embroiderer or garment decorator, school, club or University.`,
              match: 'User/Wearer',
            },
          },
        ],
        userType &&
          userType !== 'User/Wearer' && [
            {
              label: 'Website',
              name: 'companyWebsite',
              placeholder: 'Your company site',
            },
            {
              label: 'Organisation',
              name: 'companyName',
              placeholder: 'Your company name',
            },
          ],
        {
          label: 'Your enquiry',
          name: 'content',
          control: TextArea,
          required: true,
        },
        {
          label: 'I want to join your mailing list.',
          name: 'isJoiningMailingList',
          type: 'checkbox',
        },
      ]

      return fields.filter(a => a) // Remove undefined fields
    }
  )

  selectIsFormValid = createSelector(
    (state: ContactFormState) => state.formData,
    this.selectFields,
    (formData, fields) => {
      const isFormValid = validateWholeForm(formData, fields)
      return isFormValid
    }
  )

  handleFormChange = ({
    target: { name, value, type, checked },
  }: {
    target: { name: string; value?: string; type: string; checked?: boolean }
  }) => {
    const actualValue = type === 'checkbox' ? checked : value
    this.setState(state => ({
      formData: {
        ...state.formData,
        [name]: actualValue,
      },
      showFullValidation:
        (!value && (name === 'userType' || name === 'country')) || false,
    }))
  }

  handleSubmit = async (
    sendFormToApi: MutationFn,
    requestRecaptchaToken: () => Promise<string>
  ) => {
    if (!this.selectIsFormValid(this.state)) {
      this.setState({ showFullValidation: true })
      return
    }
    const { formData } = this.state

    // Wait for the request to resolve before proceeding
    let recaptchaToken

    try {
      recaptchaToken = await requestRecaptchaToken()
    } catch (error) {
      console.error(error)
    }

    if (!recaptchaToken) {
      return
    }

    const variables = {
      brand: process.env.GATSBY_BRAND,
      form: formData,
      token: recaptchaToken,
    }

    sendFormToApi({ variables })
      .then(() => {
        // Empty form if successful
        const { formData } = ContactForm.initialState
        this.setState({
          formData,
        })
      })
      .catch(error => console.error(error))
  }

  render() {
    const { as: As, className, id, ...props } = this.props
    const { formData, showFullValidation } = this.state
    const fields = this.selectFields(this.state)

    return (
      <Mutation mutation={SEND_FORM_MUTATION}>
        {(sendFormMutation, { loading, error, data }) => (
          <GetReCaptchaToken action="submitForm">
            {({
              isLoading: isLoadingRecaptcha,
              requestRecaptchaToken,
              error: tokenError,
            }) => (
              <As className={className}>
                {!data ? (
                  <>
                    <Form
                      id={id}
                      fields={fields}
                      form={formData}
                      onChange={this.handleFormChange}
                      onSubmit={() =>
                        this.handleSubmit(
                          sendFormMutation,
                          requestRecaptchaToken
                        )
                      }
                      showFullValidation={showFullValidation}
                      isLoading={loading || isLoadingRecaptcha}
                      message={
                        error || tokenError
                          ? {
                              type: 'error',
                              content: error
                                ? error.message
                                : `There was an issue attempting to obtain authorisation to submit enquiries (error: ${
                                    tokenError.message
                                  }). Try copying your enquiry and refreshing the page before attempting to submit again.`,
                            }
                          : undefined
                      }
                      name="contact"
                      method="post"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      // Disable submit button if component is waiting for a recaptcha
                      {...props}
                    />
                    <GoogleTCs />
                  </>
                ) : (
                  <FormMessage type="success">
                    {data.sendForm.message}
                  </FormMessage>
                )}
              </As>
            )}
          </GetReCaptchaToken>
        )}
      </Mutation>
    )
  }
}

export default ContactForm
