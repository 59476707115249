import * as React from 'react'
import Helmet from 'react-helmet'

type GetReCaptchaTokenProps = {
  action: string
  render?: RenderFunction
  children?: RenderFunction
}

type RenderFunction = (args: {
  reCaptchaToken: string | null
  isLoading: boolean
  requestRecaptchaToken: () => Promise<string | void>
  error: Error | null
}) => React.ReactNode

export type GetReCaptchaTokenState = {
  reCaptchaToken: string | null
  isLoading: boolean
  error: Error | null
}

interface IWindow extends Window {
  onLoadingRecaptchaApi: () => void
  grecaptcha: any
}

class GetReCaptchaToken extends React.Component<
  GetReCaptchaTokenProps,
  GetReCaptchaTokenState
> {
  state: GetReCaptchaTokenState = {
    reCaptchaToken: null,
    isLoading: false,
    error: null,
  }

  /**
   * Requests the recaptcha token. If a request was already made, returns
   * the Promise for the existing request, else make a new request.
   */
  requestRecaptchaToken = (): Promise<string | void> => {
    this.setState({ isLoading: true, error: null })

    const tokenPromise: Promise<string | void> = new Promise(
      (resolve, reject) => {
        if (!grecaptcha) {
          reject(new Error("Google reCAPTCHA library hasn't been loaded"))
        }

        const { action } = this.props
        if (!action) {
          reject(new Error('No action was provided'))
        }

        grecaptcha
          .execute(process.env.GATSBY_reCAPTCHA, {
            action,
          })
          .then((reCaptchaToken: string) => {
            if (!reCaptchaToken) {
              reject(new Error('reCAPTCHA token could not be obtained'))
            } else {
              resolve(reCaptchaToken)
            }
          })
      }
    ).then(
      (reCaptchaToken: string) => {
        this.setState({ reCaptchaToken, isLoading: false })
        return reCaptchaToken
      },
      error => {
        this.setState({ error })
      }
    )

    return tokenPromise
  }

  render() {
    const { children, render = children } = this.props
    const { reCaptchaToken, isLoading, error } = this.state

    return render({
      reCaptchaToken,
      isLoading,
      error,
      requestRecaptchaToken: this.requestRecaptchaToken,
    })
  }
}

export default GetReCaptchaToken
