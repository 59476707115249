import { colours } from '@awdis/components'
import isPlainObject from 'lodash.isplainobject'
import * as React from 'react'
import Select from 'react-select'
import { Theme } from 'react-select/lib/types'
import { createSelector } from 'reselect'
import styled from 'styled-components'

const { AZUL, WHITE, BLACK, INNER_SHADOW, PALE_GREY, ERROR } = colours

type Option = {
  label: string
  value: string
}

export type DropdownProps = {
  options: Array<Option | string>
  name: string
  onChange: (synthEvt: {
    target: { name: string; value?: string | null }
  }) => void
  value?: string
  hasError?: boolean
  required?: boolean
  info?: {
    value: string
    match?: string
  }
  isClearable?: boolean
}

const Info = styled.p`
  font-weight: bold;
  margin-top: 12px;
`

class Dropdown extends React.Component<DropdownProps> {
  static customTheme(theme: Theme): Theme {
    return {
      ...theme,
      borderRadius: 1,
    }
  }

  getCustomStyles() {
    const { hasError, required } = this.props
    return {
      option: (provided: any, state: any) => ({
        ...provided,
        background: state.isFocused ? BLACK : WHITE,
        color: state.isFocused ? WHITE : BLACK,
        fontWeight: state.isSelected ? 600 : 'normal',
      }),
      control: (provided: any, state: any) => ({
        ...provided,
        background: state.isFocused ? PALE_GREY : WHITE,
        borderColor:
          hasError && required ? ERROR : state.isFocused ? AZUL : BLACK,
        borderWidth: '1px',
        boxShadow: `inset 0 1px 3px 0 ${INNER_SHADOW}`,
        outline: 'none',
        height: '40px',
      }),
      valueContainer: (provided: React.CSSProperties) => provided,
    }
  }

  selectedFormattedOptions = createSelector(
    (_: {}, props: DropdownProps) => props.options,
    options => {
      // Transform string options into an object
      const transformedOptions = options.map(option =>
        !isPlainObject(option) ? { label: option, value: option } : option
      )
      return transformedOptions
    }
  )

  selectSelectedOption = createSelector(
    (_: {}, props: DropdownProps) => props.value,
    this.selectedFormattedOptions,
    (value, options) => {
      return options.find((option: Option) => option.value === value) || null
    }
  )

  handleChange = (option: Option | null) => {
    const { name, onChange } = this.props

    const syntheticEvent = {
      target: { name, value: option ? option.value : null },
    }
    onChange(syntheticEvent)
  }

  render() {
    const {
      options,
      onChange,
      value,
      hasError,
      required,
      info,
      ...props
    } = this.props

    const transformedOptions = this.selectedFormattedOptions(
      this.state,
      this.props
    )
    const selectedOption = this.selectSelectedOption(this.state, this.props)

    return (
      <>
        <Select
          options={transformedOptions}
          onChange={this.handleChange}
          theme={Dropdown.customTheme}
          styles={this.getCustomStyles()}
          value={selectedOption}
          {...props}
        />

        {// This will render a info under the dropdown, if match is defined, text will only display if its equal to the selected label
        info &&
          ((selectedOption && info.match === selectedOption.label) ||
            !info.match) && <Info>{info.value}</Info>}
      </>
    )
  }
}

export default Dropdown
