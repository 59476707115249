import * as React from 'react'
import styled from 'styled-components'

type GoogleTCsProps = {
  as: React.ReactType
  className?: string
}

const defaultProps = {
  as: 'small',
}

const GoogleTCsC = function GoogleTCsC({ as: As, ...props }: GoogleTCsProps) {
  return (
    <As {...props}>
      This site is protected by reCAPTCHA and the Google{' '}
      <a href="https://policies.google.com/privacy" target="_blank">
        Privacy Policy
      </a>{' '}
      and{' '}
      <a href="https://policies.google.com/terms" target="_blank">
        Terms of Service
      </a>{' '}
      apply.
    </As>
  )
}

GoogleTCsC.defaultProps = defaultProps

const GoogleTCs = styled(GoogleTCsC)`
  display: block;
  margin-top: 3em;
`

export default GoogleTCs
