import styled from 'styled-components'
import { colours } from '@awdis/components'

const { ERROR, GREY, BLACK } = colours

type MessageProps = {
  type: 'success' | 'error' | 'info'
}

const Message = styled.p<MessageProps>`
  border: 1px solid ${colours.INNER_SHADOW};
  color: ${({ type }) => {
    switch (type) {
      case 'error': {
        return ERROR
      }

      case 'info': {
        return GREY
      }

      case 'success':
      default: {
        return BLACK
      }
    }
  }};
  font-weight: ${({ type }) => (type !== 'info' ? 500 : 'normal')};
  padding: 1.45rem;
  margin-bottom: 1.45rem;

  > *:last-child {
    margin-bottom: 0;
  }
`

export default Message
