import { colours } from '@awdis/components'
import * as React from 'react'
import styled, { css } from 'styled-components'

const {
  BROWNISH_GREY,
  INNER_SHADOW,
  WHITE,
  BLACK,
  GREY,
  LIGHT_GREY,
  AZUL,
} = colours

type InputProps = {
  ref?: React.RefObject<HTMLInputElement>
  fill?: boolean
}

export const styleInput = css<InputProps>`
  background: ${WHITE};
  border: 1px solid ${BROWNISH_GREY};
  border-radius: 1px;
  box-shadow: inset 0 1px 3px 0 ${INNER_SHADOW};
  color: ${BLACK};
  font-size: 1rem;
  padding: 0.5rem;
  transition: 0.25s background, 0.25s border;
  width: ${({ fill }) => (fill ? '100%' : 'auto')};

  &:focus {
    background: ${LIGHT_GREY};
    border-color: ${AZUL};
    outline: none;
    transition: 0.2s background, 0.2s border;
  }

  :-ms-input-placeholder, /* IE 10+ */
::-webkit-input-placeholder, /* Chrome/Opera/Safari */
::-moz-placeholder, /* Firefox 19+ */
::placeholder {
    color: ${GREY};
    opacity: 0;
  }
`

const Input = styled(({ fill, ...props }) => <input {...props} />)<InputProps>`
  ${styleInput}
  height: 40px;
`

export default Input
