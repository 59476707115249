import styled from 'styled-components'
import { colours } from '@awdis/components'

type ValidationMessageProps = {}

const ValidationMessage = styled.div<ValidationMessageProps>`
  color: ${colours.ERROR};
`

export default ValidationMessage
