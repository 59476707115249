import { FormInput } from '../components/Form'

export type Validation = (
  value: string | boolean,
  form: { [name: string]: string | boolean },
  isFullValidation?: boolean
) => string | null

export const validateEmail: Validation = function validateEmail(
  value: string = ''
) {
  if (
    value &&
    !value.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    return 'Please enter a valid email'
  }

  return null
}

export const validateTelephone: Validation = function validateTelephone(
  value: string = ''
) {
  const simpleValue = value.replace(/\s/g, '')
  if (
    value &&
    !simpleValue.match(/^(\+\d{1,3}|\(\+\d{1,3}\))?(\(\d+\)|\d+)+$/)
  ) {
    return 'Please enter a valid phone number'
  }
  return null
}

export const validateUserType: Validation = function validationUserType(
  value: string = '',
  form: { [name: string]: string | boolean },
  isFullValidation: boolean = false
) {
  if (isFullValidation) {
    if (!value) {
      return 'User type is required'
    }
  }

  return null
}

const validateField = function validateField(
  form: { [name: string]: string | boolean },
  field: FormInput
): boolean {
  const { name, validate } = field
  const isValid = Boolean(!validate || !validate(form[name], form, true))
  return isValid
}

export const validateWholeForm = function validateAllForm(
  form: { [name: string]: string | boolean },
  fields: Array<FormInput | Array<FormInput>>
): boolean {
  const isValid = fields.every(fieldOrFields =>
    Array.isArray(fieldOrFields)
      ? fieldOrFields.every(field => validateField(form, field))
      : validateField(form, fieldOrFields)
  )

  return isValid
}
