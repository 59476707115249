import { properties } from '@awdis/components'
import * as React from 'react'
import styled from 'styled-components'

const { ResponsiveBreakpoints } = properties

const innerMargin = 10

type TwoColumnsProps = {
  fill?: boolean
}

const TwoColumns = styled(({ fill, ...props }) => <div {...props} />)<
  TwoColumnsProps
>`
  align-content: flex-start;
  display: flex;
  flex-flow: row nowrap;
  margin: -${innerMargin}px;
  width: ${({ fill = true }: TwoColumnsProps) => (fill ? '100%' : 'auto')};

  > * {
    flex: 0 0 50%;
    margin: ${innerMargin}px;
  }

  @media screen and (max-width: ${ResponsiveBreakpoints.Small}) {
    flex-direction: column;
  }
`

export default TwoColumns
