import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Layout, { LayoutData } from '../../components/Layout'
import TwoColumns from '../../components/TwoColumns'
import ContactForm from '../../components/ContactForm'
import Seo from '../../components/Seo'
import BasicLink from '../../components/BasicLink'

import { Title, config } from '@awdis/components'

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-weight: bold;
`

type ContactProps = {
  pageContext: {
    layoutData: LayoutData
    page: {
      title: string
      url: string
    }
  }
}

const defaultProps = {}

const OldContact = function Contact({
  pageContext: {
    layoutData,
    layoutData: { brandDescription },
    page,
  },
}: ContactProps) {
  return (
    <Layout data={layoutData}>
      <Seo
        title={page.title}
        description={brandDescription}
        type="website"
        pathname={page.url}
      />
      <TwoColumns>
        <div>
          <Title as="h2" size="small">
            Get in touch
          </Title>
          <p>
            You can reach us number of ways. The best is to fill in the contact
            form.
          </p>

          <p>
            The answers to our most common questions such as, "Where can I buy
            your products from?" & "How can I get another garment with a
            specific print?" Can be found here on our{' '}
            <StyledLink to="/faqs">FAQs page</StyledLink>
          </p>

          <p>
            You can also contact our Business Development Team on{' '}
            <BasicLink href={`tel:${config.contact.contactNumber.tel}`}>
              {config.contact.contactNumber.readable}
            </BasicLink>
            .
          </p>
        </div>

        <div>
          <ContactForm />
        </div>
      </TwoColumns>
    </Layout>
  )
}

OldContact.defaultProps = defaultProps

export default OldContact
