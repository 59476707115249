import styled, { css } from 'styled-components'
import { colours } from '@awdis/components'

type LabelProps = {
  inline?: boolean
}

const InlineVariant = css`
  display: inline-block;
  margin-bottom: 0;
  margin-right: 0.75rem;
`

const Label = styled.label<LabelProps>`
  display: block;
  color: ${colours.BLACK};
  margin-bottom: 0.6rem;

  ${({ inline = false }) => (inline ? InlineVariant : '')};
`

export default Label
