import { properties } from '@awdis/components'
import * as React from 'react'
import styled from 'styled-components'
import CheckBox from '../CheckBox'
import Input from '../Input'
import Label from './components/Label'

const { ResponsiveBreakpoints } = properties

export type LabeledInputProps = {
  label: string
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void
  control?: React.ComponentType<any> | string
  className?: string
  fill?: boolean
  inline?: boolean
  required?: boolean
  name?: string
  options?: Array<string | { label: string; value: string }>
  placeholder?: string
  type?: string
  checked?: boolean
  children?: React.ReactNode
  value?: string | number
  hasError?: boolean
  disabled?: boolean
  size?: any
}

type SingleInputProps = {
  id: string
} & LabeledInputProps

const defaultProps = {
  control: Input,
}

const UnstyledLabeledInput = function UnstyledLabeledInput({
  className,
  label,
  id,
  children,
  control: Control,
  hasError,
  inline,
  ...props
}: SingleInputProps) {
  const { type, name } = props

  return (
    <div className={className}>
      {type !== 'checkbox' ? (
        <>
          <Label htmlFor={id} inline={inline}>
            {label}
          </Label>
          <Control id={id} placeholder={label} {...props} hasError={hasError} />
        </>
      ) : (
        <CheckBox id={id} {...props}>
          {label}
        </CheckBox>
      )}
      {children}
    </div>
  )
}

UnstyledLabeledInput.defaultProps = defaultProps

const LabeledInput = styled(UnstyledLabeledInput)`
  width: ${({ fill }) => (fill ? '100%' : 'auto')};

  @media (max-width: ${ResponsiveBreakpoints.Small}) {
    width: 100%;
    display: block;
  }
`

export default LabeledInput
