import { properties } from '@awdis/components'
import * as React from 'react'
import styled from 'styled-components'
import LabeledInput from '../../../LabeledInput'

const { ResponsiveBreakpoints } = properties

type FormRowProps = {
  fill?: boolean
  stackable?: boolean
}

const FormRow = styled(({ fill, ...props }) => <div {...props} />)<
  FormRowProps
>`
  display: flex;
  flex-direction: row;
  margin: -5px -15px -5px;
  width: ${({ fill = false }) => (fill ? '100%' : 'auto')};

  > ${LabeledInput} {
    margin: 5px 15px 5px;
  }

  ${({ stackable }) =>
    stackable
      ? `
      @media screen and (max-width: ${ResponsiveBreakpoints.Small}) {
        flex-direction: column;
      }`
      : ''};
`

export default FormRow
