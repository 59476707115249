import styled from 'styled-components'
import { colours } from '@awdis/components'

export const InputCheckbox = styled.input`
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    width: 100%;
  }

  & + label:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 12px;
    height: 12px;
    background: white;
    border: 1px solid ${colours.BLACK};
    transition: background-color 0.2s ease-in-out;
    border-radius: 1.3px;
    margin-left: 0.3rem;
    margin-right: 0.6rem;
  }

  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  &:checked + label:before {
    background: ${colours.BLACK};
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:before {
    content: '✓';
    color: ${colours.WHITE};
    font-size: 8px;
    text-align: center;
  }
`
