import * as React from 'react'
import { InputCheckbox } from './styles'

type Props = {
  id: string
  children: React.ReactNode
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  name?: string
  checked?: boolean
}

const defaultProps = {
  checked: false,
}

const CheckBox = ({ id, name = id, children, ...props }: Props) => {
  return (
    <React.Fragment>
      <InputCheckbox type="checkbox" name={name || id} id={id} {...props} />
      <label htmlFor={id}>{children}</label>
    </React.Fragment>
  )
}

CheckBox.defaultProps = defaultProps

export default CheckBox
