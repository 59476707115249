import * as React from 'react'
import styled from 'styled-components'
import { styleInput } from '../Input'

const TextArea = styled(({ fill, ...props }) => <textarea {...props} />).attrs({
  rows: 10,
})`
  ${styleInput};
  resize: none;
`

export default TextArea
