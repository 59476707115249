import { Button } from '@awdis/components'
import * as React from 'react'
import styled from 'styled-components'
import { Validation } from '../../services/validation'
import LabeledInput, { LabeledInputProps } from '../LabeledInput'
import Message from '../Message'
import FormRow from './components/FormRow'
import ValidationMessage from './components/ValidationMessage'

export type FormInput = LabeledInputProps & {
  validate?: Validation
}

export interface IFormProps {
  fields: Array<FormInput | Array<FormInput>>
  id: string
  className?: string
  children?: React.ReactNode
  message?: {
    type: 'error' | 'success'
    content: string
  }
  onSubmit?: (form: { [name: string]: string | boolean }) => void
  showFullValidation?: boolean
  isLoading: boolean

  // Specifying these props allow you to store the state externally
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void
  form?: {
    [name: string]: string | boolean
  }

  // Netlify form submission fields
  name?: string
  method?: string
  'data-netlify'?: string
  'data-netlify-honeypot'?: string
  initialState?: any
}

export interface IFormState {
  form: {
    [name: string]: string | boolean
  }
}

const Filler = styled.div`
  background: transparent;
  height: 16px;
  width: 100%;
`

const FormMessage = styled(Message)`
  margin-top: 1.5em;
`

class Form extends React.Component<IFormProps, IFormState> {
  static defaultProps = {
    showFullValidation: false,
    isLoading: false,
  }

  state: IFormState = { form: this.props.initialState || {} }

  handleChangeForm = ({
    target: { type, checked, name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const actualValue = type === 'checkbox' ? checked : value
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: actualValue,
      },
    }))
  }

  handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault()
    const {
      onSubmit,
      form = this.state.form,
      showFullValidation: error,
    } = this.props

    if (error) {
      return
    }

    if (onSubmit) onSubmit(form)
  }

  renderField = ({ validate, ...props }: FormInput) => {
    const {
      id,
      onChange = this.handleChangeForm,
      form = this.state.form,
      showFullValidation, // Used to use more strict validation when submitting
    } = this.props

    const { name } = props
    const generatedId = `${id}-${name}`
    const value = form[name]

    // Validate to show error
    const validationError =
      validate && validate(value, form, showFullValidation)

    return (
      <LabeledInput
        key={generatedId}
        id={generatedId}
        onChange={onChange}
        fill
        checked={typeof value === 'boolean' ? value : undefined}
        value={typeof value === 'string' ? value : undefined}
        hasError={!!validationError}
        {...props}
      >
        <ValidationMessage>{validationError || <Filler />}</ValidationMessage>
      </LabeledInput>
    )
  }

  render() {
    const {
      fields,
      onChange,
      onSubmit,
      isLoading,
      children,
      showFullValidation,
      message,
      initialState,
      ...props
    } = this.props

    return (
      <form {...props} onSubmit={this.handleSubmit}>
        {fields.map((field, index) => (
          <FormRow
            key={Array.isArray(field) ? index : `${props.id}-${field.name}`}
            fill
            stackable
          >
            {Array.isArray(field)
              ? field.map(this.renderField)
              : this.renderField(field)}
          </FormRow>
        ))}
        {children}

        <Button type="submit" relaxed disabled={isLoading}>
          Submit{isLoading ? 'ting...' : ''}
        </Button>

        {message && (
          <FormMessage type={message.type}>{message.content}</FormMessage>
        )}
      </form>
    )
  }
}

export default Form
